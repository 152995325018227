import { FC, useState, createContext, useContext } from "react";
import {
  QueryState,
  QueryRequestContextProps,
  initialQueryRequest,
} from "../../../../../_metronic/helpers";

// Initialize sorting state here
const initialSortState: QueryState = {
  ...initialQueryRequest.state,
  sort: "created_at", // initial sort field
  order: "desc", // initial order (descending)
};

const QueryRequestContext =
  createContext<QueryRequestContextProps>(initialQueryRequest);

const QueryRequestProvider: FC = ({ children }) => {
  // Set initial sorting state
  const [state, setState] = useState<QueryState>(initialSortState);

  const updateState = (updates: Partial<QueryState>) => {
    const updatedState = { ...state, ...updates } as QueryState;
    setState(updatedState);
  };

  return (
    <QueryRequestContext.Provider value={{ state, updateState }}>
      {children}
    </QueryRequestContext.Provider>
  );
};

const useQueryRequest = () => useContext(QueryRequestContext);
export { QueryRequestProvider, useQueryRequest };
