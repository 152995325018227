/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useEffect, useRef, useState } from "react";
import { Invoice } from "../invoice/invoices-list/core/_models";
import { useParams } from "react-router-dom";
import {
  getInvoiceById,
  uploadInvoicePdf,
} from "../invoice/invoices-list/core/_requests";
import { invoice } from "../../modules/auth/core/_requests";
import { generatePdf } from "../../utils/generatePdf";
import { KTSVG } from "../../../_metronic/helpers";
import ButtonLoader from "../../components/ButtonLoader";
import { toast, toastConfig, ToastOptions } from "../../utils/toastService";

type Props = {
  className: string;
  page?: string;
};

const SpecificationsPage: React.FC<Props> = ({ className, page }) => {
  const params = useParams();
  const [invoiceId, setInvoiceId] = useState(""); // eslint-disable-line
  const [invoiceData, setInvoiceData] = useState<Invoice>();
  const reportTemplateRef = useRef<HTMLDivElement | null>(null);
  const [deviceWidth, setDeviceWidth] = useState(window.innerWidth);
  const [downloadLoading, setDownloadLoading] = React.useState(false);
  const [uploadLoading, setUploadLoading] = React.useState(false);

  let Invoice = [];
  let electricals: any = [];
  let plumbingFinishes: any = [];
  let tiles: any = [];
  let hardwood: any = [];
  let appliances: any;
  let countertops: any = [];

  useEffect(() => {
    let invoice_id = String(params[Object.keys(params)[0]]);
    if (invoice_id) {
      setInvoiceId(invoice_id);
      getInvoice(invoice_id);
    }
  }, [params]);

  useEffect(() => {
    // Update the device width when the window is resized
    const handleResize = () => {
      setDeviceWidth(window.innerWidth);
    };

    // Attach the event listener
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  Invoice = invoice().data;

  let localStorageElectricals: any = localStorage.getItem("electricals");
  let tempSavedElectricals: any = JSON.parse(localStorageElectricals);
  // electricals = tempSavedElectricals?.data || [];
  if (invoiceData) {
    const invoiceElectrical = invoiceData.invoice_payload.inventories.find(
      (inventory: any) => inventory.type === "electrical"
    );
    electricals = invoiceElectrical?.data?.data || [];
  } else {
    electricals = tempSavedElectricals?.data || [];
  }

  let localStoragePlumbingFinishes: any =
    localStorage.getItem("plumbing_finishes");
  let tempSavedPlumbingFinishes: any = JSON.parse(localStoragePlumbingFinishes);
  // plumbingFinishes = tempSavedPlumbingFinishes?.data || [];
  if (invoiceData) {
    const invoicePlumbingFinishes =
      invoiceData.invoice_payload.inventories.find(
        (inventory: any) => inventory.type === "plumbingFinishes"
      );
    plumbingFinishes = invoicePlumbingFinishes?.data?.data || [];
  } else {
    plumbingFinishes = tempSavedPlumbingFinishes?.data || [];
  }

  let localStorageTiles: any = localStorage.getItem("tiles");
  let tempSavedTiles: any = JSON.parse(localStorageTiles);
  // tiles = tempSavedTiles?.data || [];
  if (invoiceData) {
    const invoiceTile = invoiceData.invoice_payload.inventories.find(
      (inventory: any) => inventory.type === "tile"
    );
    tiles = invoiceTile?.data?.data || [];
  } else {
    tiles = tempSavedTiles?.data || [];
  }

  let localStorageHardwood: any = localStorage.getItem("hardwood");
  let tempSavedHardwood: any = JSON.parse(localStorageHardwood);
  // hardwood = tempSavedHardwood?.data || [];
  if (invoiceData) {
    const invoiceHardwood = invoiceData.invoice_payload.inventories.find(
      (inventory: any) => inventory.type === "hardwood"
    );
    hardwood = invoiceHardwood?.data?.data || [];
  } else {
    hardwood = tempSavedHardwood?.data || [];
  }

  let localStorageAppliances: any = localStorage.getItem("appliances");
  let tempSavedAppliances: any = JSON.parse(localStorageAppliances);
  // appliances = tempSavedAppliances?.data || undefined;
  if (invoiceData) {
    const invoiceAppliances = invoiceData.invoice_payload.inventories.find(
      (inventory: any) => inventory.type === "appliances"
    );
    appliances = invoiceAppliances?.data?.data || [];
  } else {
    appliances = tempSavedAppliances?.data || [];
  }

  let localStorageCounterTops: any = localStorage.getItem("counter_tops");
  let tempSavedCountertops: any = JSON.parse(localStorageCounterTops);
  // countertops = tempSavedCountertops?.data || [];
  if (invoiceData) {
    const invoiceCounterTops = invoiceData.invoice_payload.inventories.find(
      (inventory: any) => inventory.type === "countertops"
    );
    countertops = invoiceCounterTops?.data?.data || [];
  } else {
    countertops = tempSavedCountertops?.data || [];
  }

  const getInvoice = async (id: any) => {
    let invoiceData = await getInvoiceById(id);
    if (invoiceData) {
      let parsedInvoiceData = JSON.parse(invoiceData.invoice_payload);
      invoiceData["invoice_payload"] = parsedInvoiceData;
      setInvoiceData(invoiceData);
    }
  };

  const handleGeneratePdf = async (type: string) => {
    if (type === "upload") {
      setUploadLoading(true);
    } else {
      setDownloadLoading(true);
    }
    let pdfBuffer: any = await generatePdf({
      reportTemplateRef,
      invoiceData,
      fileName: "specification.pdf",
      format: "a4",
      orientation: "p",
      scale: 1.4,
      download: type === "download" ? true : false,
    });

    if (pdfBuffer && type === "upload") {
      const formData = new FormData();
      // const pdfBlob = new Blob([pdfBuffer], { type: "application/pdf" });
      // formData.append("specification", pdfBlob, "specification.pdf");
      formData.append("siteId", invoiceData?.suite?.site_id!);
      formData.append("suiteId", invoiceData?.suite?.id!);
      formData.append("suiteNumber", invoiceData?.suite?.suite_number!);
      formData.append("customerId", invoiceData?.customer?.customer_id!);
      formData.append("customerName", invoiceData?.customer?.full_name!);
      formData.append("invoiceId", invoiceData?.id!);
      formData.append("specification", JSON.stringify(1) );
      formData.append("finishing", JSON.stringify(0));

      try {
        let response: any = await uploadInvoicePdf(formData);
        toast.success(response.data.message, toastConfig as ToastOptions);
      } catch (error) {
        toast.error(
          "Error while uploading to Procore",
          toastConfig as ToastOptions
        );
        console.error("Error uploading PDF:", error);
      }
    }
    if (type === "upload") {
      setUploadLoading(false);
    } else {
      setDownloadLoading(false);
    }
  };

  return (
    <>
      <div
        className={`card ${className}`}
        style={{ maxWidth: "1600px !important" }}
        ref={reportTemplateRef}
      >
        <div className="card-body py-3 pt-8">
          {/* begin::Table container */}
          {/* <div className="table-responsive"> */}
          {/* begin::Table */}

          {/* Electrical section start */}
          {electricals.length && (
            <>
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label fw-bolder fs-3 mb-1">
                  Electrical
                </span>
              </h3>
              <div className="card-body py-3">
                <div className="table-responsive">
                  <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                    <thead>
                      <tr className="fw-bolder text-muted">
                        <th className="min-w-120px">Type</th>
                      </tr>
                    </thead>
                    <tbody>
                      {electricals.map((data: any, index: number) => (
                        <tr key={data.id}>
                          {data.cost && data.unit ? (
                            <td>
                              <a
                                href="#"
                                className="text-dark fw-bolder text-hover-primary fs-6"
                              >
                                {data.type}
                              </a>
                            </td>
                          ) : null}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </>
          )}
          {/* Electrical section end */}

          {/* Plumbing section start */}
          {plumbingFinishes.length && (
            <>
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label fw-bolder fs-3 mb-1">
                  Plumbing Finishes
                </span>
              </h3>
              <div className="card-body py-3">
                <div className="table-responsive">
                  <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                    <thead>
                      <tr className="fw-bolder text-muted text-start">
                        <th className="min-w-60px">Area</th>
                        <th className="min-w-20px">Fixture</th>
                        <th className="min-w-120px">
                          Client fixture selection
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {plumbingFinishes.map((data: any, index: number) => (
                        <tr key={index}>
                          <td>
                            <a
                              href="#"
                              className="text-dark fw-bolder text-hover-primary fs-6"
                            >
                              {data.area}
                            </a>
                          </td>
                          <td>
                            {data.fixture.map((fixture: any, index: number) => (
                              <div
                                key={index}
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <a
                                  href="#"
                                  className="text-dark fw-bolder text-hover-primary fs-6"
                                  style={{
                                    lineHeight: "1",
                                    marginBottom: "10px",
                                  }}
                                >
                                  {fixture.fixture_name}
                                </a>
                              </div>
                            ))}
                          </td>
                          <td className="text-center">
                            {data.fixture.map(
                              (fixture: any, fixture_index: number) =>
                                fixture.client_fixture_selection.map(
                                  (selection: any, index: number) => (
                                    <div
                                      key={index}
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <a
                                        href="#"
                                        className="text-dark fw-bolder text-hover-primary fs-6"
                                        style={{
                                          lineHeight: "1",
                                          marginBottom: "10px",
                                        }}
                                      >
                                        {fixture.unit_cost ===
                                        selection.unit_cost
                                          ? selection.selection
                                          : null}
                                      </a>
                                    </div>
                                  )
                                )
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </>
          )}
          {/* Plumbing section end */}

          {/* Tile section start */}
          {tiles.length && (
            <>
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label fw-bolder fs-3 mb-1">Tiles</span>
              </h3>
              <div className="card-body py-3">
                <div className="table-responsive">
                  <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                    <thead>
                      <tr className="fw-bolder text-muted">
                        <th className="min-w-160px">Area</th>
                        <th className="min-w-120px">Type/Model</th>
                      </tr>
                    </thead>
                    <tbody>
                      {tiles.map((data: any, index: number) => (
                        <tr key={index} style={{ lineHeight: "50px" }}>
                          <td>
                            <a
                              href="#"
                              className="text-dark fw-bolder text-hover-primary fs-6"
                            >
                              {data.area}
                            </a>
                          </td>
                          <td>
                            <a
                              href="#"
                              className="text-dark fw-bolder text-hover-primary fs-6"
                            >
                              {data.selectedTile ||
                                data.option_selected[0].tile_selection[0]
                                  .tile_selection_option}
                            </a>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </>
          )}
          {/* Tile section end */}

          {/* Hardwood section start */}
          {hardwood.length && (
            <>
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label fw-bolder fs-3 mb-1">Hardwood</span>
              </h3>
              <div className="card-body py-3">
                <div className="table-responsive">
                  <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                    <thead>
                      <tr className="fw-bolder text-muted">
                        <th className="min-w-160px">Area</th>
                        <th className="min-w-120px" style={{ width: "25%" }}>
                          Type/Model
                        </th>
                        <th className="min-w-120px" style={{ width: "20%" }}>
                          Direction
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {hardwood.map((data: any, index: number) => (
                        <tr key={index} style={{ lineHeight: "50px" }}>
                          <td>
                            <a
                              href="#"
                              className="text-dark fw-bolder text-hover-primary fs-6"
                            >
                              {data.area}
                            </a>
                          </td>
                          <td>
                            <a
                              href="#"
                              className="text-dark fw-bolder text-hover-primary fs-6"
                            >
                              {data.selectedWood ||
                                data.option_selected[0].wood_selection[0]
                                  .wood_selection_option}
                            </a>
                          </td>
                          <td className="text-start">
                            <a
                              href="#"
                              className="text-dark fw-bolder text-hover-primary fs-6"
                            >
                              {data.selectedDirection || data.direction[0]}
                            </a>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </>
          )}
          {/* Hardwood section end */}

          {/* Appliances section start */}
          {appliances && (
            <>
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label fw-bolder fs-3 mb-1">
                  Appliances
                </span>
              </h3>
              <div className="card-body py-3">
                <div className="table-responsive">
                  <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                    <thead>
                      <tr className="fw-bolder text-muted">
                        <th className="min-w-160px">Option Selected</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr style={{ lineHeight: "50px" }}>
                        <td>
                          <a
                            href="#"
                            className="text-dark fw-bolder text-hover-primary fs-6"
                          >
                            {appliances?.package_type ||
                              appliances[0]?.standard_option?.package_type}
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </>
          )}
          {/* Appliances section end */}

          {/* Countertops section start */}
          {countertops.length && (
            <>
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label fw-bolder fs-3 mb-1">
                  Countertops
                </span>
              </h3>
              <div className="card-body py-3">
                <div className="table-responsive">
                  <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                    <thead>
                      <tr className="fw-bolder text-muted">
                        <th className="min-w-160px">Area</th>
                        <th className="min-w-120px">Type/Model</th>
                        <th className="min-w-120px">Edge</th>
                      </tr>
                    </thead>
                    <tbody>
                      {countertops.map((data: any, index: number) => (
                        <tr key={index} style={{ lineHeight: "50px" }}>
                          <td>
                            <a
                              href="#"
                              className="text-dark fw-bolder text-hover-primary fs-6"
                            >
                              {data.area}
                            </a>
                          </td>
                          <td className="text-start">
                            <a
                              href="#"
                              className="text-dark fw-bolder text-hover-primary fs-6"
                            >
                              {data.counter_selection}
                            </a>
                          </td>
                          <td className="text-start">
                            <a
                              href="#"
                              className="text-dark fw-bolder text-hover-primary fs-6"
                            >
                              {data.edge_detail}
                            </a>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </>
          )}
          {/* Countertops section end */}

          {/* end::Table */}
          {/* </div> */}
          {/* end::Table container */}
        </div>
        {/* begin::Body */}
      </div>
      {deviceWidth >= 576 && page === "default" ? (
        <div className="d-grid gap-2 d-md-flex justify-content-md-end">
          <button
            onClick={() => handleGeneratePdf("download")}
            type="button"
            className="btn btn-primary mt-2"
          >
            {!downloadLoading && (
              <span className="indicator-label">
                Download
                <KTSVG
                  path="/media/icons/duotune/arrows/arr065.svg"
                  className="svg-icon-2"
                />
              </span>
            )}
            {downloadLoading && <ButtonLoader />}
          </button>
          <button
            onClick={() => handleGeneratePdf("upload")}
            type="button"
            className="btn btn-primary mt-2"
          >
            {!uploadLoading && (
              <span className="indicator-label">
                Upload to procore
                <KTSVG
                  path="/media/icons/duotune/arrows/arr003.svg"
                  className="svg-icon-2"
                />
              </span>
            )}
            {uploadLoading && <ButtonLoader />}
          </button>
        </div>
      ) : null}
    </>
  );
};

export { SpecificationsPage };
