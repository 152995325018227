import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
// swiper core styles
import "swiper/swiper.min.css";

// swiper bundle styles
import "swiper/swiper-bundle.min.css";

// modules styles
import "swiper/modules/navigation/navigation.min.css";
import "swiper/modules/pagination/pagination.min.css";

// custom css for swiper slider module
import "./swiper-custom.css";

type Props = {
  imagearray: any;
  CloseSlider: any;
};

const SwiperCommon: React.FC<Props> = ({ imagearray, CloseSlider }) => {
  return (
    <div
      className="slider-custom-class"
      style={{
        position: "fixed",
        width: "40vw",
        height: "35vw",
        backgroundColor: "rgba(0, 0, 0, 0.426)",
        zIndex: "999",
        display: `flex`,
        alignItems: "center",
        left: "32%",
        top: "10%",
      }}
      id="slider-id"
    >
      <i
        onClick={CloseSlider}
        style={{ position: "absolute", top: "20px", right: "20px" }}
      >
        <img
          src="/media/images/slider/icons/close.png"
          style={{ width: 20, cursor: "pointer" }}
          alt="close"
        />
      </i>

      <Swiper
        modules={[Navigation]}
        navigation
        spaceBetween={5}
        slidesPerView={1}
      >
        {imagearray.map((photo: string | undefined, index: number) => (
          <SwiperSlide
            key={index}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img src={photo} alt="slide" />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default SwiperCommon;
